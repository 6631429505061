import { Component, AfterViewInit } from '@angular/core';
import * as XLSX from 'xlsx';
import * as L from 'leaflet';
import '../../node_modules/leaflet.boatmarker/leaflet.boatmarker.js';
import { DbService } from './db.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {
  title = 'waves-poc';
  data: any;
  map: any;
  loading: boolean;
  geo: any;

  constructor(
    private db: DbService
  ) {
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    //this.loadMap();
  }



  createIcons(data) {
    let geo = {
      "type": "FeatureCollection",
      "features": []
    };
    for (let row of data) {
      let date = new Date("1900-01-01")
      date.setDate(date.getDate() + row[0]);
      //console.log(date);
      geo.features.push({
        "type": "Feature",
        "properties": {
          "start": date.toJSON(),
          "end": date.toJSON()
        },
        "geometry": {
          "type": "Point",
          "coordinates": [row[2], row[1]]
        }
      })
    }
    this.geo.addData(geo)
    this.map.panTo([data[0][1], data[0][2]]);
  }
}
