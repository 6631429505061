<div class="clr-row">
    <div class="clr-col">
        <div id="map"></div>
    </div>
</div>

<div class="clr-row">
    <div class="clr-col-8">
        <div class="card">
            <div class="card-block">
                <div class="btn-group btn-outline-primary btn-sm btn-icon">
                    <button (click)="rewind()" [disabled]="playing || timeChangeLoading" class="btn btn-sm btn-icon"><clr-icon shape="rewind" class="is-solid"></clr-icon></button>
                    <button (click)="backStep()" [disabled]="playing || timeChangeLoading" class="btn btn-sm btn-icon"><clr-icon shape="step-forward" dir="down" class="is-solid"></clr-icon></button>
                    <button (click)="pause()" [disabled]="timeChangeLoading" *ngIf="playing" class="btn btn-sm btn-icon"><clr-icon shape="pause" class="is-solid"></clr-icon></button>
                    <button (click)="play()" [disabled]="timeChangeLoading" *ngIf="!playing" class="btn btn-sm btn-icon"><clr-icon shape="play" class="is-solid"></clr-icon></button>
                    <button (click)="playStep()" [disabled]="playing || timeChangeLoading" class="btn btn-sm btn-icon"><clr-icon shape="step-forward" class="is-solid"></clr-icon></button>
                </div>
                {{current|date:'long'}} &nbsp;
                <span *ngIf="timeChangeLoading" class="spinner spinner-inline">
                    Loading...
                </span>
                <span *ngIf="timeChangeLoading">
                    Loading...
                </span>
            </div>
            <div class="card-block">
                <form clrForm>
                    <clr-range-container [clrRangeHasProgress]="true">
                        <label>Time</label>
                        <input type="range" [disabled]="timeChangeLoading" clrRange [(ngModel)]="currentTime" [max]="latestTime" [min]="earliestTime" [step]="1000" name="timeslider" (change)="timeChange($event)" />
                        <clr-control-helper>Warning: Current implementation is slow when skipping forward</clr-control-helper>
                    </clr-range-container>
                    <clr-range-container [clrRangeHasProgress]="true">
                        <label>Play Speed</label>
                        <input [disabled]="timeChangeLoading" type="range" clrRange [(ngModel)]="playSpeed" name="playSpeedReverse"  min="1" max="120" />
                        <clr-control-helper>Number of seconds each frame represents (lower = smoother animation)</clr-control-helper>
                    </clr-range-container>
                    <clr-input-container>
                        <label>Milliseconds per Frame</label>
                        <input [disabled]="timeChangeLoading" clrInput type="number" name="mpf" [(ngModel)]="milliperframe" />
                        <clr-control-helper>Milliseconds before moving to the next frame</clr-control-helper>
                      </clr-input-container>

                </form>
            </div>
            <div class="card-block">
                <table class="table table-vertical">
                    <tr>
                        <th>Start</th>
                        <td>{{earliest|date:'long'}}</td>
                    </tr>
                    <tr>
                        <th>End</th>
                        <td>{{latest|date:'long'}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    <div class="clr-col-4">
        <div class="card">
            <div *ngFor="let vessel of vesselLayers | keyvalue" class="card-block" (mouseover)="popupVessel(vessel.key)">
            <clr-icon style="color:{{vessel.key|vesselColour}}" shape="circle" class="is-solid"></clr-icon> {{vessels[vessel.key]}} ({{vessel.key}})
            </div>
        </div>

    </div>
</div>