import { Injectable } from '@angular/core';
import * as PouchDB from 'pouchdb-browser/lib';
import * as pouchFind from 'pouchdb-find';


@Injectable({
  providedIn: 'root'
})
export class DbService {
  db: PouchDB;
  animation: PouchDB;
  features: Array<any> = [];

  constructor() {
    const find: PouchDB.Plugin = (pouchFind as any).default;
    PouchDB.plugin(find);
    this.db = new PouchDB("data");
  }

  getDelta(previousTime: Date, options?: { new?: Date, bookmark?: number }): Promise<any> {
    let onesecond: Date;
    if (options) {
      if (options.new) {
        onesecond = options.new;
      } else {
        onesecond = new Date(previousTime);
        onesecond.setSeconds(onesecond.getSeconds() + options.bookmark);
      }
    } else {
      onesecond = new Date(previousTime);
      onesecond.setSeconds(onesecond.getSeconds() + 1);
    }

    let selector = {
      selector: {
        '_id': {
          "$gte": "f:" + previousTime.toJSON(),
          "$lt": "f:" + onesecond.toJSON() + ':\ufff0'
        }
      }
    };
    

    if (options) {
      if (options.new) {
        selector.selector['_id']["$lt"] = "f:" + options.new.toJSON();
      } else {
        selector["limit"] = 10000;
      }
    }

    return new Promise((resolve, reject) => {
      this.db.find(selector)
        .then(
          res => {
            console.log("getDelta", `Getting AIS records between ${previousTime.toJSON()} and ${onesecond.toJSON()}`, res.docs.length);
            resolve({ docs: res.docs, newtime: onesecond });
          },
          err => {
            reject(err);
          }
        )
    });
  }

  getData(time: Date): Promise<{ docs: Array<any>, newtime: Date }> {
    return new Promise((resolve, reject) => {
      let count = 0;
      let results = [];
      this.db.allDocs({ startkey: 'f:', endkey: 'f:' + time.toJSON() + '\ufff0', include_docs: true })
        .then(
          res => {
            console.log("getData", "AIS records from start to "+time.toJSON(), res.rows.length);
            resolve({
              docs: res.rows.map((val) => val.doc),
              newtime: time
            })
          }
        )
    })
  }

  getVessels(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.db.allDocs({ include_docs: true, startkey: 'v:', endkey: 'v:\ufff0' })
        .then(
          res => {
            resolve(res.rows.map((val) => {
              return val.doc;
            }))
          },
          err => {
            reject(err)
          }
        )
    })
  }

  getTimeRange() {
    let start: Date;
    let end: Date;
    return new Promise((resolve, reject) => {

      this.db.allDocs({ startkey: "f:", include_docs: true, endkey: "f:\ufff0", limit: 1 })
        .then(
          res => {
            start = new Date(res.rows[0].doc.properties.start);
            return this.db.allDocs({ startkey: "f:\ufff0", include_docs: true, endkey: "f:", limit: 1, descending: true })
          }
        )
        .then(
          res => {
            end = new Date(res.rows[0].doc.properties.start);
            resolve({ start: start, end: end })
          }
        )
        .catch((err) => {
          console.error("getTimeRange", err)
          reject(err)
        })

    })
  }
}
