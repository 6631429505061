



<div class="main-container">

  <header class="header-6">
    <div class="branding">
      <a [routerLink]="['/']" class="nav-link">
        <span class="title">AIS Demo</span>
    </a>
    </div>
    <div class="header-nav">
      <a [routerLink]="['/import']" routerLinkActive="active"class="nav-link nav-text">Import</a>
      <a [routerLink]="['/view']" routerLinkActive="active"class="nav-link nav-text">View</a>
    </div>
</header>
  <div class="content-container">
      <div class="content-area">
        <router-outlet></router-outlet>
        <div *ngIf="loading">Loading...</div>
        
        <table class="sjs-table">
            <tr *ngFor="let row of data">
              <td *ngFor="let val of row">
                {{val}}
              </td>
            </tr>
          </table>
        
      </div>
  </div>
</div>