import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import * as PouchDB from 'pouchdb-browser/lib';
import { DbService } from '../db.service';


@Component({
  selector: 'app-open',
  templateUrl: './open.component.html',
  styleUrls: ['./open.component.css']
})
export class OpenComponent implements OnInit {
  loading: boolean;
  loaded: boolean;
  vessels: any[];
  selections:any = {};
  data:XLSX.WorkBook;
  start:Date;
  end:Date;
  currentStatus:string;
  totalFeatures:number;
  totalCount:number = 0;
  processing: Boolean;

  constructor(
    private db: DbService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  onFileChange(evt: any) {
    this.loading = true;
    this.processing = true;
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
      this.data = wb;
      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      //console.log("sheets", wb.SheetNames)
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      let data = <any>(XLSX.utils.sheet_to_json(ws, { header: 1 }));

      this.db.db.destroy()
      .then(
        res => {
          console.log("destroy db", res)
          this.db.db = new PouchDB("data");
          this.generateVesselList(wb)
        }
      )
      this.loading = false;
    };
    reader.readAsBinaryString(target.files[0]);
  }

  generateVesselList(workbook : any) {
    this.vessels = [];
    for(let sheet of workbook.SheetNames) {
      //console.log(workbook.Sheets[sheet])
      let data = <any>(XLSX.utils.sheet_to_json(workbook.Sheets[sheet], { header: 1 }))
      this.vessels.push({
        id:sheet,
        imo:workbook.Sheets[sheet]['I4'] ? workbook.Sheets[sheet]['I4'].v : '000000000', 
        name:workbook.Sheets[sheet]['J4'] ? workbook.Sheets[sheet]['J4'].v : 'Unknown',
        rows:data.length,
        data:data
      });
    }

    this.totalFeatures = this.vessels.reduce((prev, cur) => {
      return prev + cur.rows;
    }, 0)
    
    let vesselsBulk = this.vessels.map((val) => {
      val._id = 'v:'+val.id;
      return val;
    });

    this.db.db.bulkDocs(vesselsBulk)
    .then(
      res => {
        console.log("vessel list uploaded")
      },
      err => {
        console.error(err)
      }
    )
    this.loaded = true;
    this.startUpload(0);
  }

  startUpload(i?:number) {
    this.currentStatus = this.vessels[i].name;
      this.uploadData(this.vessels[i].data, i, this.vessels[i].id)
      .then(
        res => {
          console.log("Uploaded", i);
          i++;
          if(i < this.vessels.length) {
            this.startUpload(i);
          } else {
            this.router.navigate(['/view'])
          }
        }
      )

  }

  uploadData(data : any, index : number, id:string) {
    //console.log("Uploading", data)
    let total = 1;
    let count = 0;

    let earliest : Date;
    let latest : Date;

    let features = data.map((val, index, arr) => {
      var e0date = new Date(0); // epoch "zero" date
      var offset = e0date.getTimezoneOffset(); // tz offset in min
      let fraction = val[0] - Math.floor(val[0]);

      let start = new Date(0, 0, val[0], 0, 0, fraction*86400);
      let end = new Date(0);

      if (index + 1 == data.length) {
        fraction = val[0] - Math.floor(val[0]);
        end = new Date(0, 0, val[0], 0, 0, fraction*86400);
      } else {
        fraction = data[index + 1][0] - Math.floor(data[index + 1][0]);
        //console.log(arr[index + 1])
        end = new Date(0, 0, data[index + 1][0], 0, 0, fraction*86400);
      }

      let feature = {
        "_id": `f:${start.toJSON()}:${id}`,
        "type": "Feature",
        "properties": {
          "start": start.toJSON(),
          "end": end.toJSON(),
          "time": start.toJSON(),
          "heading":val[4],
          "speed":val[3],
          "length":val[13],
          "width":val[16],
          "name":val[9],
          "imo":val[8]
        },
        "geometry": {
          "type": "Point",
          "coordinates": [val[2], val[1]]
        }
      };
      return feature;
    });


    return new Promise((resolve, reject) => {
      let count = 0;
      
      let featurestotal = features.length;
      this.vessels[index]["total"] = featurestotal;


     
      let addToDB = (features, db) => {
        //console.log("features", features)
        let featuresplit = features.splice(0, 100);

        featuresplit = featuresplit.filter((val) => {
          let start = new Date(val.properties.start);
          let timestamp = start.getTime()
          //console.log("Filter", start,  start.getTime(), !isNaN(timestamp) && timestamp > 0 && Math.sign(timestamp) == 1);
          return !isNaN(timestamp) && timestamp > 0 && Math.sign(timestamp) == 1;
        });

        db.bulkDocs(featuresplit)
          .then(
            res => {
              if (features.length) {
                count += featuresplit.length;

                this.totalCount += featuresplit.length;
                //console.log("added " + count + " of " + featurestotal, res);
                this.vessels[index]["count"] = count;
                addToDB(features, db);
              } else {
                resolve(`Uploaded ${count} of ${featurestotal}`);
              }
            },
            err => {
              console.error(err)
            }
          )
      };

      addToDB(features, this.db.db);
    })

  }

}
