import { Pipe, PipeTransform } from '@angular/core';
import * as stc from 'string-to-color';

@Pipe({
  name: 'vesselColour'
})
export class VesselColourPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    return stc.default(value);
  }

}
