import { Component, OnInit } from '@angular/core';
import * as L from 'leaflet';
import { DbService } from '../db.service';
import * as stc from 'string-to-color';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {
  map: any;
  geo: any;
  time: any;
  earliest: Date;
  latest: Date;
  current: Date;
  earliestTime: number;
  latestTime: number;
  currentTime: number;
  vessels: any = {};
  vesselList: Array<any> = [];
  vesselLayers: any = {};
  playSpeed: number = 60;
  playSpeedReverse: number = 1500;
  playing: boolean;
  milliperframe: number = 200;
  updateQueue: Array<any> = [];
  updating: boolean;
  limiter: any;
  timeChangeLoading:boolean;

  constructor(
    private db: DbService
  ) {
  }

  ngOnInit() {
    this.getVessels();

    this.db.getTimeRange()
      .then(
        (res: { start: Date, end: Date }) => {
          this.setTime(res.start, res.start, res.end);
          this.loadMap();
        },
        err => {
          console.error("error", err)
        }
      )
  }

  playSpeedChange() {
    this.playSpeed = 2000 - this.playSpeedReverse;
  }

  getVessels() {
    this.db.getVessels()
      .then(
        res => {
          this.vesselList = res;
        },
        err => {
          console.error(err)
        }
      )
  }

  setTime(current: Date | number, start?: Date, end?: Date) {
    if (start) {
      this.earliest = start;
      this.earliestTime = start.getTime();
    }

    if (end) {
      this.latest = end;
      this.latestTime = end.getTime();
    }

    if (current instanceof Date) {
      this.current = current;
      this.currentTime = current.getTime();
    } else {
      this.current = new Date(current);
      this.currentTime = current;
    }
  }

  timeChange(event) {
    this.timeChangeLoading = true;
    let backwards = this.currentTime < this.current.getTime();
    this.setTime(this.currentTime);
    this.getData(backwards)
    .then(
      res => {
        this.timeChangeLoading = false;
      }
    )
  }

  loadMap() {
    this.map = L.map('map').setView([51.505, -0.09], 13);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.map);

    var OpenSeaMap = L.tileLayer('https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png', {
      attribution: 'Map data: &copy; <a href="http://www.openseamap.org">OpenSeaMap</a> contributors'
    });
    OpenSeaMap.addTo(this.map)

    this.getData(false, true);

    this.geo = L.Layer;

  }

  play() {
    this.playing = true;
    this.playStep(this.playSpeed);
  }

  pause() {
    this.playing = false;
  }

  playStep(bookmark?: number) {
    return new Promise((resolve, reject) => {
      let options = bookmark ? { bookmark: bookmark } : { bookmark: this.playSpeed };
      if (this.currentTime < this.latestTime) {
        //this.setTime(this.currentTime += 600000)
        this.db.getDelta(this.current, options)
          .then(
            res => {
              //console.log(res)
              this.setTime(res.newtime)
              return this.updateVessels(res.docs)
            }
          )
          .then(
            res => {
              resolve();
              if (this.playing) {
                setTimeout(() => {
                  this.playStep(bookmark);
                }, this.milliperframe)
              }
            }
          )
      } else {
        this.setTime(this.latestTime);
        this.db.getDelta(this.current, options)
          .then(
            res => {
              this.updateVessels(res.docs);
              this.playing = false;
            }
          )
      }
    })
  }

  rewind() {
    this.currentTime = this.earliestTime;
    this.setTime(this.currentTime);
    this.getData(true)
  }

  backStep() {
    if (this.currentTime > this.earliestTime) {
      let start = new Date(this.current);
      start.setSeconds(start.getSeconds() - this.playSpeed)
      this.db.getDelta(start, { new: this.current })
        .then(
          res => {
            this.updateVessels(res.docs);
            this.setTime(start);
          }
        )
    }
  }

  popupContent(vessel: any) {
    return `
    <div><b>Name:</b> ${vessel.properties.name}</div>
    <div><b>IMO No.:</b> ${vessel.properties.imo}</div>
    <div><b>Heading:</b> ${vessel.properties.heading}</div>
    <div><b>SOG (kts):</b> ${vessel.properties.speed}</div>
    <div><b>Last Seen:</b> ${vessel.properties.start}</div>
    `;
  }

  popupVessel(vesselId: string | number) {
    this.vesselLayers[vesselId].openPopup();
  }

  updateVessels(data: Array<any>) {
    return new Promise((resolve, reject) => {
      let count = 0;
      let iterator = (queue: Array<any>) => {
        if (queue.length) {
          let vessel = queue.shift();
          this.updating = true;
          this.updateVessel(vessel)
            .then(
              res => {
                iterator(queue);
              }
            )
        } else {
          this.updating = false;
          resolve();
        }
      }
      if (data.length && !this.updating) {
        iterator(data);
      } else {
        resolve();
      }
    })
  }

  updateVessel(vessel: any) {
    return new Promise((resolve, reject) => {

      let vesselId = vessel._id.split(':').pop();
      this.vessels[vesselId] = vessel.properties.name;

      var newLatLng = new L.LatLng(vessel.geometry.coordinates[1], vessel.geometry.coordinates[0]);
      if (this.vesselLayers.hasOwnProperty(vesselId) && this.map.hasLayer(this.vesselLayers[vesselId])) {

        this.vesselLayers[vesselId].setLatLng(newLatLng);

      } else {

        this.vesselLayers[vesselId] = L.boatMarker(newLatLng, {
          color: stc.default(vesselId), 	// color of the boat
          idleCircle: false,	// if set to true, the icon will draw a circle if boatspeed == 0 and the ship-shape if speed > 0
        });
        this.vesselLayers[vesselId].addTo(this.map);
      }
      this.vesselLayers[vesselId].setHeading(Number(vessel.properties.heading));
      this.vesselLayers[vesselId].bindPopup(this.popupContent(vessel));
      resolve()
    })
  }
  
  getData(backwards?: boolean, second?: boolean) {
    let layers = Object.keys(this.vesselLayers);
    let newlayers: Array<string>;
    //console.log(this.vesselList)

    let option = second ? { bookmark: 1 } : { new: this.current };

    return new Promise((resolve, reject) => {
      this.db.getData(this.current)
        .then(
          res => {
            // console.log(res)
            if (backwards) {
              for (let vessel of Object.keys(this.vesselLayers)) {

                this.vesselLayers[vessel].remove();

              }
            }
            this.updateVessels(res.docs);

            let first = Object.keys(this.vesselLayers)[0];
            if (first) {
              this.map.panTo(this.vesselLayers[first].getLatLng());
            }
            resolve();
          }
        )

    })
  }

}
