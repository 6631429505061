<h3>Import File</h3>
<p>Please select an AIS data spreadsheet to start import</p>
<input type="file" (change)="onFileChange($event)" multiple="false" />


<div >
    <div class="clr-row">
        <div class="clr-col-12 clr-col-sm-6">
            <div *ngIf="processing" class="card">
                <div  class="card-block">
                    <h4 class="card-title">Importing Data</h4>
                    <p class="card-text">Please wait while data is imported... <span *ngIf="totalFeatures">{{totalCount|number}} of {{totalFeatures|number}} Records</span></p>
                    <p *ngIf="loading">
                        <span class="spinner spinner-inline">
                            Loading...
                        </span>
                        <span>
                            Loading...
                        </span>
                    </p>
                    <div *ngIf="loaded" class="progress-block">
                        <label>{{currentStatus}}</label>
                        <clr-progress-bar [clrValue]="totalCount" [clrMax]="totalFeatures" clrDisplayval="{{totalCount/totalFeatures | percent}}"></clr-progress-bar>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
